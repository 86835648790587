import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

function CardList({ list }) {
	return (
		<S.Container>
			{list.map(({ description, title, icon: { imgix_url } }, index) => (
				<S.Item key={index}>
					<S.IconContainer>
						<S.Icon src={imgix_url} alt={title} />
					</S.IconContainer>
					<S.Title>{title}</S.Title>
					<S.Description>{description}</S.Description>
				</S.Item>
			))}
		</S.Container>
	);
}

CardList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			title: PropTypes.string,
			imgix_url: PropTypes.string,
		})
	).isRequired,
};

export default CardList;
